<template>
    <transition name="fade">
        <div
            v-if="isAlwaysInDom || isVisible"
            v-show="isVisible"
            ref="contentElement"
            :class="[$style.root, shadowLevel >= 0 && $style.root_shadow, $style[theme]]"
            :style="contentElementStyle"
            :data-test-id="$attrs['data-test-id'] || 'affluent-popover'"
        >
            <div
                v-if="hasArrow"
                ref="arrowElement"
                :class="[$style.arrow, shadowLevel >= 0 && $style.arrow_shadow]"
                :style="arrowStyle"
            />
            <div :class="$style.slot">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import type { AutoUpdateOptions, Coords, OffsetOptions, Placement } from "@floating-ui/dom";
import { arrow, autoUpdate, computePosition, offset as offsetMiddleware } from "@floating-ui/dom";
import { ComponentPublicInstance, computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";

import { MIDDLEWARES } from "./middlewares";
import { PopoverMiddleware, PopoverPlacement, PopoverStrategy, PopoverTheme } from "./types";

export interface AffluentPopoverProps {
    /** Anchor reference */
    anchor?: ComponentPublicInstance | HTMLElement;
    /** Side of anchor where popover appears */
    placement?: PopoverPlacement;
    /** Offset from anchor in px */
    offset?: number | OffsetOptions;
    /** Popover position absolute or fixed */
    strategy?: PopoverStrategy;
    /** Middleware array, supports: shift, flip, size, autoPlacement, hide, inline, */
    middleware?: PopoverMiddleware[];
    /** Is popover visible? */
    isVisible?: boolean;
    /** Popover have arrow? */
    hasArrow?: boolean;
    /** Shadow level (negative values disable shadow). Allowed values: integers from -1 to 3 */
    shadowLevel?: number;
    /** Popover color scheme */
    theme?: PopoverTheme;
    /** Auto update options */
    autoUpdateOptions?: AutoUpdateOptions;
    /** Should the popover exist in the DOM when hidden? By default, it's removed/not mounted into DOM, when not visible. */
    isAlwaysInDom?: boolean;
    /** Should the popover use anchor width? */
    shouldUseAnchorWidth?: boolean;
}

defineOptions({ name: "AffluentPopover" });

const props = withDefaults(defineProps<AffluentPopoverProps>(), {
    placement: PopoverPlacement.BottomStart,
    strategy: PopoverStrategy.Absolute,
    middleware: () => [],
    isVisible: false,
    hasArrow: false,
    shadowLevel: 1,
    theme: PopoverTheme.Light,
    autoUpdateOptions: () => ({ elementResize: false }),
});

const leftRight = new Set(["left", "right"]);
const topBottom = new Set(["bottom", "top"]);

const contentElement = ref();
const arrowElement = ref();
const arrowStyle = ref({
    top: `0`,
    left: `0`,
    transform: `translate(0,0) rotate(45deg)`,
});
const contentElementTransform = ref(`translate(0,0)`);
const contentElementStyle = computed(() => ({
    position: props.strategy,
    width: contentElementWidth.value,
    transform: contentElementTransform.value,
    "--shadow": `var(--box-shadow-${props.shadowLevel})`,
}));
const anchor = computed(() => (props.anchor instanceof HTMLElement ? props.anchor : props.anchor?.$el));
const contentElementWidth = computed(() => (props.shouldUseAnchorWidth ? anchor.value?.offsetWidth : undefined));

const destroyPopover = ref<() => void | undefined>();

const update = async () => {
    if (!anchor.value || !contentElement.value) return;

    const middleware = [
        ...(props.offset ? [offsetMiddleware(props.offset)] : []),
        ...props.middleware.map((middlewareItem) => MIDDLEWARES[middlewareItem.type](middlewareItem.options)),
        ...(props.hasArrow ? [arrow({ element: arrowElement.value })] : []),
    ];

    const { middlewareData, placement, x, y } = await computePosition(anchor.value, contentElement.value, {
        strategy: props.strategy,
        placement: props.placement,
        middleware,
    });

    contentElementTransform.value = `translate(${x}px,${y}px)`;

    if (middlewareData.arrow) setArrowPosition(middlewareData.arrow, placement);
};

const setArrowPosition = (arrowCoords: Partial<Coords> & { centerOffset: number }, placement: Placement) => {
    const [staticSide] = placement.split("-");
    const { x, y } = arrowCoords;
    const xTranslate = leftRight.has(staticSide) ? "-50%" : 0;
    const yTranslate = topBottom.has(staticSide) ? "-50%" : 0;
    const arrowRotation: Record<string, number> = {
        bottom: 45,
        left: 135,
        top: 225,
        right: 315,
    };

    arrowStyle.value = {
        top: y === undefined ? "" : `${y}px`,
        left: x === undefined ? "" : `${x}px`,
        transform: `translate(${xTranslate},${yTranslate}) rotate(${arrowRotation[staticSide]}deg)`,
        [staticSide]: "calc(100%)",
    };
};

const setContentPosition = async () => {
    if (props.isVisible && anchor.value)
        destroyPopover.value = autoUpdate(anchor.value, contentElement.value, update, props.autoUpdateOptions);
    else destroyPopover.value?.();
};

watch(() => props.placement, setContentPosition);

onMounted(() => {
    setContentPosition();
    watch([() => props.isVisible, () => props.anchor], async () => {
        await nextTick();
        setContentPosition();
    });
});

onBeforeUnmount(() => destroyPopover.value?.());
</script>

<style lang="scss" module>
.root {
    top: 0;
    left: 0;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    isolation: isolate;
}

.light {
    --background-color: var(--color-white);
}

.dark {
    --background-color: var(--color-black);
    color: var(--color-white);
}

.root_shadow {
    --shadow: none;
    box-shadow: var(--shadow);
}

.arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    background: linear-gradient(135deg, var(--background-color), var(--background-color) 50%, transparent 51%);
    pointer-events: none;
}

.arrow_shadow {
    box-shadow: var(--box-shadow-arrow);
}

.slot {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);
}
</style>
